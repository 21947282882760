<template>
  <div>
    <PreSalesTable
      :items="sales"
      hover
      striped
      border
      small
      fixed
      caption="Apartados"
      @refresh="refreshItems"
    />
  </div>
</template>
<script>

import PreSalesTable from '../../components/sales/PreSalesTable.vue'
import sales from '../../services/sales';

import store from '../../store'

export default {
  name: 'PreSales',
  components: {
    PreSalesTable
  },
  data: function () {
		return {
            sales: []
        }
  },
  mounted: async function() {
    this.loading();
    let response = await sales.getPreSales();

    if(response.type == "success"){
      this.sales = response.data;
    }

    this.loaded();
  },
  methods: {
    async refreshItems (items){
      this.loading();
      this.sales = await items;
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
